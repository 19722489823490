'use strict';
jQuery(document).ready(function($){

   
    $('.referentie-slider').slick({
        infinite: true,
        autoplay: false,
        fade: true,
        dots:true,
        pauseOnHover: false,
        nextArrow: $('.section-five .next')
      });

    //Behandelingen slider
    var referentieSlides = $('.glide .slide-item');
    var perViewLength = 3;
      var sliderType = 'slider';
    
      if(referentieSlides.length > 3){
        sliderType = 'carousel';
      
      } else if(referentieSlides.length < 4){
        $('.glide .glide__arrows').hide();
        perViewLength =  referentieSlides.length;
      }
      var glide = new Glide('.glide', {
        type: sliderType,
        perView: perViewLength,
        gap:20,
        peek: {
            before: 10,
            after: 100
        },
        breakpoints: {
            1000: {
              perView: 2,
              peek: {
                before: 10,
                after: 300
                }
            },
            600: {
                perView: 1,
                peek: {
                  before: 0,
                  after: 0
                  },
                gap:0
              }
         }
      });
      if(referentieSlides.length > 0){
        glide.mount();
      }
});